import styles from "./Header.module.css";
import { Button } from "../Button/Button";
import { contractAddress } from "../../utils/contract";

export function Header() {
   return (
      <div className={styles.wrap}>
         <div className={styles.logo}>
            {/* <img src={Images.logo} alt="Fast&Furious" /> */}
         </div>
         <div className={styles.info}>
            <div>THE</div>
            PAPER <span className={styles.unlimited}></span>HOUSE
            <div className={styles.subTitle}>
               Up TO <span className={styles["bg-red"]}>8%</span> DAILY
            </div>
            <div className={styles.buttons}>
               <Button href="https://docs.google.com/gview?embedded=true&url=https://paperhouseminer.org/audit.pdf">
                  Audit
               </Button>
               <Button
                  href={`https://www.bscscan.com/address/${contractAddress}`}
               >
                  BSC
               </Button>
            </div>
         </div>
      </div>
   );
}
