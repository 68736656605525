import bsc from "./assets/bsc.png";
import bnb from "./assets/bnb.png";
import jotform from "./assets/jotform.png";
import help from "./assets/help.png";
import linktee from "./assets/linktee.png";
import tel from "./assets/tel.png";
import nairobi from "./assets/nairobi.png";
import berlin from "./assets/berlin.png";
import professor from "./assets/professor.png";
import tokyo from "./assets/tokyo.png";
import paper from "./assets/paper.png";
import bgTimer from "./assets/timer-bg.jpg";
import blinking from "./assets/blinking_new.gif";

const Images = {
   bsc,
   bnb,
   jotform,
   help,
   linktee,
   tel,
   tokyo,
   professor,
   berlin,
   nairobi,
   paper,
   bgTimer,
   blinking,
};

export default Images;
