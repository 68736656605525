import { dailyTimeString, DAY } from "../../utils/time_util";
import { Timer } from "../Timer/Timer";
import styles from "./CycleIndicator.module.css";
function cycleDay(checkpoint: number) {
   return Math.floor(((new Date().getTime() / 1000 - checkpoint) / DAY) % 14);
}
function dayStatus(day: number, checkpoint: number, hatches: number) {
   return day > cycleDay(checkpoint)
      ? "upcoming"
      : day === cycleDay(checkpoint) && ((2 ** day) & hatches) !== 2 ** day
      ? "current"
      : ((2 ** day) & hatches) === 2 ** day
      ? "done"
      : "failed";
}
export function CycleIndicator({
   hatches,
   checkpoint,
}: {
   hatches: number;
   checkpoint: number;
}) {
   return (
      <div>
         <ul className={styles.wrap}>
            {[...Array(13)].map((_, i) => (
               <i
                  key={i}
                  className={`${styles.circle} ${styles["circle-" + (i + 1)]} ${
                     dayStatus(i, checkpoint, hatches) === "failed"
                        ? styles.failed
                        : dayStatus(i, checkpoint, hatches) === "current"
                        ? styles.active
                        : dayStatus(i, checkpoint, hatches) === "done"
                        ? styles.done
                        : null
                  }`}
               ></i>
            ))}
            <Timer
               timeStr={dailyTimeString(
                  DAY - ((new Date().getTime() / 1000 - checkpoint) % DAY)
               )}
            />
            {/* <i
               key={13}
               className={`${styles.active} ${styles.circle} ${
                  styles[`circle-14`]
               }`}
            >
               {cycleDay(checkpoint) < 13 ? "waiting" : "timer"}
            </i> */}
         </ul>
      </div>
   );
}
