import styles from "./ReferralView.module.css";
import { Button } from "../Button/Button";
import copy from "copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import Label from "../Label/Label";

const baseUrl = "https://paperhouseminer.org/";

export const ReferralView = ({
   refBonus,
}: {
   refBonus: string;
   totalInvestment: string;
}) => {
   const { account } = useWeb3React();
   function handleClick() {
      if (account) {
         copy(`${baseUrl}?ref=${account}`);
         alert("Your link copied successfully");
      }
   }
   return (
      <div className={`box ${styles.wrap}`}>
         <p>
            There are 4 levels of referral bonus. 5%, 3%, 2%, 2%. The referral
            bonus will paid on Bank Note Print. Because of mandatory Print's you
            will earn a lot. Referrals Commission will be added as Bank Notes to
            your Bank.
         </p>
         <Label label="REFERRAL MONEY" value={refBonus} />
         <div className={styles.tools}>
            <Button bgColor="red" onClick={handleClick}>
               Copy My Link
            </Button>
         </div>
      </div>
   );
};
