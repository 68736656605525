import { hatchEggs, sellEggs } from "../../utils/contract";
import { days } from "../../utils/time_util";
import { Button } from "../Button/Button";
import Label from "../Label/Label";
import styles from "./UserView.module.css";

function withdrawalAllowed(distance: number, hatchery: number) {
   var d = days(distance);
   return (
      d >= 13 && d % 14 === 13 && (hatchery & (2 ** 13 - 1)) === 2 ** 13 - 1
   );
}

export interface IUserViewProps {
   miners: string;
   eggs: string;
   tvl: string;
   eggsBNB: string;
   invested: string;
   withdrawal: string;
   checkpoint: string;
   hatchery: string;
}

export const UserView = ({
   miners,
   eggs,
   eggsBNB,
   tvl,
   invested,
   checkpoint,
   hatchery,
}: IUserViewProps) => {
   return (
      <div className={`${styles["card-wrapper"]}`}>
         <Label label="GANGS" value={miners} />
         <Label label="MONEY" value={eggsBNB} unit="BNB" />
         {/* <Label label="YOUR TREASURY BANK" value={tvl} unit="BNB" /> */}
         {/* <Label label="Total Invested" value={invested} />
         <Label label="Withdrawn" value={withdrawal} /> */}
         {/* <div className={styles.total}>{grogBNB}</div> */}
         {parseFloat(invested) > 0 && (
            <div>
               <div className={styles.tools}>
                  {withdrawalAllowed(
                     new Date().getTime() / 1000 - parseInt(checkpoint),
                     parseInt(hatchery)
                  ) ? (
                     <Button onClick={() => sellEggs()} bgColor="green">
                        CASH OUT
                     </Button>
                  ) : null}
                  <Button
                     onClick={() => {
                        hatchEggs();
                     }}
                     bgColor="red"
                  >
                     PRINT
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};
